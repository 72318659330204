var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditLayout",
    {
      attrs: {
        "supports-photo": "",
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        photo: _vm.photo
      },
      on: {
        submit: function($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        }
      }
    },
    [
      _c("div", { attrs: { slot: "sidebar-bottom" }, slot: "sidebar-bottom" }, [
        _c("div", { staticClass: "formType form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("formType")))]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.form_type,
                  expression: "item.form_type"
                }
              ],
              staticClass: "form-control",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.item,
                    "form_type",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(" " + _vm._s(_vm.$t("none")) + " ")
              ]),
              _vm._l(_vm.formTypes, function(formType) {
                return _c(
                  "option",
                  { key: formType, domProps: { value: formType } },
                  [_vm._v(" " + _vm._s(formType) + " ")]
                )
              })
            ],
            2
          )
        ]),
        _c("div", { staticClass: "metaIndexling form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("metaIndexing")))]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.seo_indexing,
                  expression: "item.seo_indexing"
                }
              ],
              staticClass: "form-control",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.item,
                    "seo_indexing",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(" " + _vm._s(_vm.$t("indexing")) + " ")
              ]),
              _vm._l(_vm.indexing, function(index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: index } },
                  [_vm._v(" " + _vm._s(index) + " ")]
                )
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }